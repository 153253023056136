<template>
  <div class="card">
    <h3 class="card-title">LISTAR OBRAS SOCIALES</h3>
    <div v-if="obras_sociales">
      <table-list
        ref="tablelist"
        :showFilter="true"
        :data="obras_sociales"
        :header="headers"
        :body="body"
        :actionButton="actionButton"
        @rowClicked="showObraSocial"
      ></table-list>
    </div>
    <modal v-if="openNew" @close="openNew = false">
      <nueva-obra-social slot="modal-body" @saved="refresh"></nueva-obra-social>
    </modal>
  </div>
</template>
<script>
import { ObraSocialServices } from "../services/ObraSocialServices";
import TableList from "@/components/elements/TableList";
import Modal from "@/components/elements/Modal";
import NuevaObraSocial from "./NuevaObraSocial.vue";
export default {
  components: { TableList, Modal, NuevaObraSocial },
  name: "ListarObrasSociales",
  data() {
    return {
      obras_sociales: null,
      headers: ObraSocialServices.headers(),
      body: ObraSocialServices.body(),
      actionButton: {
        text: "Nueva obra social",
        action: () => {
          this.openNew = true;
        },
      },
      openNew: false,
    };
  },
  mounted() {
    ObraSocialServices.api.getAll().then((obras_sociales) => (this.obras_sociales = obras_sociales));
  },
  methods: {
    refresh() {
      this.openNew = false;
      ObraSocialServices.api.getAll().then((obras_sociales) => {
        this.obras_sociales = obras_sociales;
        this.$refs['tablelist'].refresh(obras_sociales)
      });
    },
    showObraSocial(obra_social){
      this.$router.push({name:'VerObraSocial', params:{id:obra_social.id}})
    }
  },
};
</script>